import axiosApi from '../../../utils/axiosApi';
import {
  GET_ATHLETES_REQUEST,
  GET_ATHLETES_SUCCESS,
  GET_ATHLETES_FAILURE,
  GET_ROLES_FAILURE,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_SINGLE_ATHLETE_REQUEST,
  GET_SINGLE_ATHLETE_SUCCESS,
  GET_SINGLE_ATHLETE_FAILURE,
} from './actionTypes';
import { showNotification } from '../../../utils/showNotification';

const getAthletesRequest = () => ({ type: GET_ATHLETES_REQUEST });
const getAthletesSuccess = (athletes) => ({ type: GET_ATHLETES_SUCCESS, athletes });
const getAthletesFailure = () => ({ type: GET_ATHLETES_FAILURE });

export const getAthletes = (name = null, role = null, validated = null) => {
  return async (dispatch) => {
    try {
      dispatch(getAthletesRequest());
      const response = await axiosApi.get('/user', {
        params: {
          Name: name,
          RoleId: role,
          ValidatedOnly: validated,
        },
      });
      dispatch(getAthletesSuccess(response.data.data));
    } catch (error) {
      dispatch(getAthletesFailure());
    }
  };
};

const getRolesRequest = () => ({ type: GET_ROLES_REQUEST });
const getRolesSuccess = (roles) => ({ type: GET_ROLES_SUCCESS, roles });
const getRolesFailure = () => ({ type: GET_ROLES_FAILURE });

export const getRoles = () => {
  return async (dispatch) => {
    try {
      dispatch(getRolesRequest());
      const response = await axiosApi.get('/role');
      dispatch(getRolesSuccess(response.data.data));
    } catch (error) {
      dispatch(getRolesFailure());
    }
  };
};

const getSingleAthleteRequest = () => ({ type: GET_SINGLE_ATHLETE_REQUEST });
const getSingleAthleteSuccess = (singleAthlete) => ({ type: GET_SINGLE_ATHLETE_SUCCESS, singleAthlete });
const getSingleAthleteFailure = () => ({ type: GET_SINGLE_ATHLETE_FAILURE });

export const getSingleAthlete = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getSingleAthleteRequest());
      const response = await axiosApi.get(`/user/${id}`);
      dispatch(getSingleAthleteSuccess(response.data?.data));
    } catch (error) {
      dispatch(getSingleAthleteFailure());
    }
  };
};

export const editAthlete = (data) => {
  return async () => {
    try {
      const response = await axiosApi.put('/user', data);
      return response;
    } catch (error) {
      showNotification('error', error.response?.data.error);
    }
  };
};

export const deleteUser = (id) => {
  return async () => {
    try {
      const response = await axiosApi.delete(`/user?id=${id}`);
      showNotification('success', response?.data?.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};
