import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  GET_USER_EMAIL_REQUEST,
  GET_USER_EMAIL_SUCCESS,
  GET_USER_EMAIL_FAILURE,
  GET_USER_INFORMATION_REQUEST,
  GET_USER_INFORMATION_SUCCESS,
  GET_USER_INFORMATION_FAILURE,
  GET_USER_BYID_REQUEST,
  GET_USER_BYID_SUCCESS,
  GET_USER_BYID_FAILURE,
  GET_USER_RACES_REQUEST,
  GET_USER_RACES_SUCCESS,
  GET_USER_RACES_FAILURE,
} from './actionTypes';
import { saveToLocalStorage } from '../../../utils/localStorage';
import axiosApi from '../../../utils/axiosApi';
import { showNotification } from '../../../utils/showNotification';

const loginUserRequest = () => ({ type: LOGIN_USER_REQUEST });
const loginUserSuccess = (profile) => ({ type: LOGIN_USER_SUCCESS, profile });
const loginUserFailure = () => ({ type: LOGIN_USER_FAILURE });

export const loginUser = (profile) => {
  return (dispacth) => {
    try {
      dispacth(loginUserRequest());
      dispacth(loginUserSuccess(profile));
    } catch (error) {
      dispacth(loginUserFailure());
    }
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    saveToLocalStorage('user', {});
    dispatch({ type: LOGOUT_USER });
    try {
    } catch (error) {}
  };
};

const getUserEmailRequest = () => ({ type: GET_USER_EMAIL_REQUEST });
const getUserEmailSuccess = (email) => ({ type: GET_USER_EMAIL_SUCCESS, email });
const getUserEmailFailure = () => ({ type: GET_USER_EMAIL_FAILURE });

export const getAdditionalUserInfo = () => {
  return async (dispatch) => {
    try {
      dispatch(getUserEmailRequest());
      const response = await axiosApi.get('/user/me');
      dispatch(getUserEmailSuccess(response?.data?.data?.email));
      return true;
    } catch (error) {
      dispatch(getUserEmailFailure());
    }
  };
};

export const updateEmail = (email) => {
  return async () => {
    try {
      const response = await axiosApi.put('/user/update-email', { email });
      showNotification('success', response?.data.message);
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

const getUserInformationRequest = () => ({ type: GET_USER_INFORMATION_REQUEST });
const getUserInformationSuccess = (userInformation) => ({ type: GET_USER_INFORMATION_SUCCESS, userInformation });
const getUserInformationFailure = () => ({ type: GET_USER_INFORMATION_FAILURE });

export const getUserProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(getUserInformationRequest());
      const response = await axiosApi.get('/user/me');
      dispatch(getUserInformationSuccess(response?.data?.data));
    } catch (error) {
      dispatch(getUserInformationFailure());
    }
  };
};

export const updateUserProfile = (data) => {
  return async () => {
    try {
      await axiosApi.put('/user', data);
      showNotification('success', 'User information has been updated');
      return true;
    } catch (error) {
      showNotification('error', error.response?.data.error);
      return false;
    }
  };
};

const getUserByIDRequest = () => ({ type: GET_USER_BYID_REQUEST });
const getUserByIDSuccess = (userByID) => ({ type: GET_USER_BYID_SUCCESS, userByID });
const getUserByIDFailure = () => ({ type: GET_USER_BYID_FAILURE });

export const getUserByID = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getUserByIDRequest());
      const response = await axiosApi.get(`/user/${id}`);
      dispatch(getUserByIDSuccess(response?.data?.data));
    } catch (error) {
      dispatch(getUserByIDFailure());
      //
    }
  };
};

const getUserRacesRequest = () => ({ type: GET_USER_RACES_REQUEST });
const getUserRacesSuccess = (myRaces) => ({ type: GET_USER_RACES_SUCCESS, myRaces });
const getUserRacesFailure = () => ({ type: GET_USER_RACES_FAILURE });

export const getUserRaces = (registerd = null, type = null, from = null, to = null, completed = null) => {
  return async (dispatch) => {
    try {
      dispatch(getUserRacesRequest());
      const response = await axiosApi.get('/race', {
        params: {
          RegisteredOnly: registerd ? registerd : null,
          Type: type !== null ? type : null,
          from,
          to,
          CompletedOnly: completed ? completed : null,
        },
      });
      dispatch(getUserRacesSuccess(response?.data.data.items));
    } catch (error) {
      dispatch(getUserRacesFailure());
    }
  };
};
