export const GET_ATHLETES_REQUEST = 'GET_ATHLETES_REQUEST';
export const GET_ATHLETES_SUCCESS = 'GET_ATHLETES_SUCCESS';
export const GET_ATHLETES_FAILURE = 'GET_ATHLETES_FAILURE';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const GET_SINGLE_ATHLETE_REQUEST = 'GET_SINGLE_ATHLETE_REQUEST';
export const GET_SINGLE_ATHLETE_SUCCESS = 'GET_SINGLE_ATHLETE_SUCCESS';
export const GET_SINGLE_ATHLETE_FAILURE = 'GET_SINGLE_ATHLETE_FAILURE';
