import React from 'react';

export const AppContext = React.createContext();

export function AppContextProvider({ children, isLoggedIn, isAdmin, hasRole, hasEmail, isRaceOrganazier, isRacer }) {
  return (
    <AppContext.Provider value={{ isLoggedIn, isAdmin, hasRole, hasEmail, isRaceOrganazier, isRacer }}>
      {children}
    </AppContext.Provider>
  );
}
