export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_USER_EMAIL_REQUEST = 'GET_USER_EMAIL_REQUEST';
export const GET_USER_EMAIL_SUCCESS = 'GET_USER_EMAIL_SUCCESS';
export const GET_USER_EMAIL_FAILURE = 'GET_USER_EMAIL_FAILURE';

export const GET_USER_INFORMATION_REQUEST = 'GET_USER_INFORMATION_REQUEST';
export const GET_USER_INFORMATION_SUCCESS = 'GET_USER_INFORMATION_SUCCESS';
export const GET_USER_INFORMATION_FAILURE = 'GET_USER_INFORMATION_FAILURE';

export const GET_USER_BYID_REQUEST = 'GET_USER_BYID_REQUEST';
export const GET_USER_BYID_SUCCESS = 'GET_USER_BYID_SUCCESS';
export const GET_USER_BYID_FAILURE = 'GET_USER_BYID_FAILURE';

export const GET_USER_RACES_REQUEST = 'GET_USER_RACES_REQUEST';
export const GET_USER_RACES_SUCCESS = 'GET_USER_RACES_SUCCESS';
export const GET_USER_RACES_FAILURE = 'GET_USER_RACES_FAILURE';
