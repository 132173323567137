import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getAdditionalUserInfo, updateEmail } from '../../store/actions/user/userActions';
import './styles.scss';

const UpdateEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);

  const updateEmailHandler = async () => {
    const response = await dispatch(updateEmail(email));
    if (response) dispatch(getAdditionalUserInfo());
  };
  return (
    <div className="content">
      <div className="update-email__form">
        <h2 className="title m-b-30">{t('verifyEmailText')}</h2>
        <Input
          placeholder="Update email"
          size="large"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="email"
        />
        <div className="update-email__button">
          <Button size="large" onClick={updateEmailHandler}>
            {t('buttons.update')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmail;
