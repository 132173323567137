import axiosApi from '../../../utils/axiosApi';
import {
  GET_GENERAL_INFO_REQUEST,
  GET_GENERAL_INFO_SUCCESS,
  GET_GENERAL_INFO_FAILURE,
  GET_MYRACES_REQUEST,
  GET_MYRACES_SUCCESS,
  GET_MYRACES_FAILURE,
  GET_FAV_CIRCLE_REQUEST,
  GET_FAV_CIRCLE_SUCCESS,
  GET_FAV_CIRCLE_FAILURE,
  GET_MY_CUPS_REQUEST,
  GET_MY_CUPS_SUCCESS,
  GET_MY_CUPS_FAILURE,
} from './actionTypes';

const getGeneralInfoRequest = () => ({ type: GET_GENERAL_INFO_REQUEST });
const getGeneralInfoSuccess = (generalInfo) => ({ type: GET_GENERAL_INFO_SUCCESS, generalInfo });
const getGeneralInfoFailure = () => ({ type: GET_GENERAL_INFO_FAILURE });

export const getGeneralInfo = () => {
  return async (dispatch) => {
    try {
      dispatch(getGeneralInfoRequest());
      const response = await axiosApi.get('/user/dashboard');
      dispatch(getGeneralInfoSuccess(response.data?.data));
    } catch (error) {
      dispatch(getGeneralInfoFailure());
    }
  };
};

const getMyracesRequest = () => ({ type: GET_MYRACES_REQUEST });
const getMyracesSuccess = (myRaces) => ({ type: GET_MYRACES_SUCCESS, myRaces });
const getMyracesFailure = () => ({ type: GET_MYRACES_FAILURE });

export const getMyRaces = (
  finished = null,
  registered = null,
  completed = null,
  subscribed = null,
  DescOrder = null
) => {
  return async (dispatch) => {
    try {
      dispatch(getMyracesRequest());
      const response = await axiosApi.get('/race', {
        params: {
          RegisteredOnly: registered,
          FinishedOnly: finished,
          CompletedOnly: completed,
          SubscribedCirclesOnly: subscribed,
          DescOrder: DescOrder,
        },
      });
      dispatch(getMyracesSuccess(response?.data?.data?.items));
    } catch (error) {
      dispatch(getMyracesFailure());
    }
  };
};

const getFavCirclesRequest = () => ({ type: GET_FAV_CIRCLE_REQUEST });
const getFavCirclesSuccess = (favCircles) => ({ type: GET_FAV_CIRCLE_SUCCESS, favCircles });
const getFavCirclesFailure = () => ({ type: GET_FAV_CIRCLE_FAILURE });

export const getFavoriteCircles = () => {
  return async (dispatch) => {
    try {
      dispatch(getFavCirclesRequest());
      const response = await axiosApi.get('/circle', {
        params: {
          SubscribedOnly: true,
        },
      });
      dispatch(getFavCirclesSuccess(response?.data?.data.items));
    } catch (error) {
      dispatch(getFavCirclesFailure());
    }
  };
};

const getMyCupsRequest = () => ({ type: GET_MY_CUPS_REQUEST });
const getMyCupsSuccess = (myCups) => ({ type: GET_MY_CUPS_SUCCESS, myCups });
const getMyCupsFailure = () => ({ type: GET_MY_CUPS_FAILURE });

export const getMyCups = (registeredOnly = true) => {
  return async (dispatch) => {
    try {
      dispatch(getMyCupsRequest());
      const response = await axiosApi.get('/cup', {
        params: {
          registeredOnly,
        },
      });
      dispatch(getMyCupsSuccess(response.data?.data?.items));
    } catch (error) {
      dispatch(getMyCupsFailure());
    }
  };
};
