export const GET_GENERAL_INFO_REQUEST = 'GET_GENERAL_INFO_REQUEST';
export const GET_GENERAL_INFO_SUCCESS = 'GET_GENERAL_INFO_SUCCESS';
export const GET_GENERAL_INFO_FAILURE = 'GET_GENERAL_INFO_FAILURE';

export const GET_MYRACES_REQUEST = 'GET_MYRACES_REQUEST';
export const GET_MYRACES_SUCCESS = 'GET_MYRACES_SUCCESS';
export const GET_MYRACES_FAILURE = 'GET_MYRACES_FAILURE';

export const GET_FAV_CIRCLE_REQUEST = 'GET_FAV_CIRCLE_REQUEST';
export const GET_FAV_CIRCLE_SUCCESS = 'GET_FAV_CIRCLE_SUCCESS';
export const GET_FAV_CIRCLE_FAILURE = 'GET_FAV_CIRCLE_FAILURE';

export const GET_MY_CUPS_REQUEST = 'GET_MY_CUPS_REQUEST';
export const GET_MY_CUPS_SUCCESS = 'GET_MY_CUPS_SUCCESS';
export const GET_MY_CUPS_FAILURE = 'GET_MY_CUPS_FAILURE';
